@import "../../styles/index";

.base {
  position: absolute;
  left: 1rem;

  top: 1rem;

  width: 3rem;
  height: 3rem;

  @include media-from(md) {
    left: 2rem;

    width: 4rem;
    height: 4rem;
  }

  border-radius: 50%;

  background-color: $color-chinese-black;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 250ms, box-shadow 250ms;

  &:hover {
    transform: translate(0.5rem, -0.5rem);

    box-shadow: -0.5rem 0.5rem 0px 0px $color-royal-pruple;
  }
}

.icon {
  height: 1.25rem;
  width: 1.25rem;

  @include media-from(md) {
    height: 1.5rem;
    width: 1.5rem;
  }
}
