@import "../../styles/index";

.base {
  font-size: $font-size-12;
  text-align: center;
  color: $color-philippine-gray;

  @include media-to(xs) {
    margin-bottom: 2rem;
  }
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}

.link {
  margin: 0 0.25rem;

  transition: color 250ms;

  &:hover {
    color: $color-ube;
  }
}
