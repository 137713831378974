@import "../../styles/index";

.base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;

  padding: 1rem;
  height: calc(100vh - 2rem);

  @include media-from(xs) {
    padding: 2rem;
    height: calc(100vh - 3rem);
  }

  @include media-from(sm) {
    padding: 4rem;
    height: calc(100vh - 8rem);
  }

  @include media-from(md) {
    padding: 5rem;

    height: calc(100vh - 10rem);
  }

  @include media-from(lg) {
    padding: 6rem;

    height: calc(100vh - 12rem);
  }
}

.headline {
  font-size: $font-size-24;
  font-weight: 700;
  min-height: 32px;
  margin: 0 0 4rem 0;

  @include media-from(md) {
    min-height: 116px;

    font-size: $font-size-44;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 250ms, color 250ms, box-shadow 250ms;

  padding: 0.5rem 0.75rem;
  background-color: $color-eerie-black;
  border-radius: 10px;

  font-size: $font-size-18;

  &:hover {
    transform: translate(0.5rem, -0.5rem);

    box-shadow: -0.5rem 0.5rem 0px 0px $color-royal-pruple;
  }
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;

  @include media-from(md) {
    height: 1.5rem;
    width: 1.5rem;
  }
}
