@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;700&display=swap);
.Error_base__3Hd2K{display:flex;flex-direction:column;justify-content:center;align-items:center;max-height:100vh;padding:1rem;height:calc(100vh - 2rem)}@media only screen and (min-width: 576px){.Error_base__3Hd2K{padding:2rem;height:calc(100vh - 3rem)}}@media only screen and (min-width: 768px){.Error_base__3Hd2K{padding:4rem;height:calc(100vh - 8rem)}}@media only screen and (min-width: 992px){.Error_base__3Hd2K{padding:5rem;height:calc(100vh - 10rem)}}@media only screen and (min-width: 1200px){.Error_base__3Hd2K{padding:6rem;height:calc(100vh - 12rem)}}.Error_headline__1GXRq{font-size:1.5rem;font-weight:700;min-height:32px;margin:0 0 4rem 0}@media only screen and (min-width: 992px){.Error_headline__1GXRq{min-height:116px;font-size:2.75rem}}.Error_button__3_h-k{display:flex;justify-content:center;align-items:center;transition:color 250ms, box-shadow 250ms, -webkit-transform 250ms;transition:transform 250ms, color 250ms, box-shadow 250ms;transition:transform 250ms, color 250ms, box-shadow 250ms, -webkit-transform 250ms;padding:0.5rem 0.75rem;background-color:#15171d;border-radius:10px;font-size:1.125rem}.Error_button__3_h-k:hover{-webkit-transform:translate(0.5rem, -0.5rem);transform:translate(0.5rem, -0.5rem);box-shadow:-0.5rem 0.5rem 0px 0px #7747b6}.Error_icon__2_CFY{height:1.25rem;width:1.25rem;margin-right:0.5rem}@media only screen and (min-width: 992px){.Error_icon__2_CFY{height:1.5rem;width:1.5rem}}

.Avatar_base__1TXzk{border-radius:50%;background-color:#111217;margin:0 1rem 0 0;width:3rem;height:3rem;-webkit-user-select:none;-ms-user-select:none;user-select:none}@media only screen and (min-width: 576px){.Avatar_base__1TXzk{margin:0 2rem 0 0;width:5rem;height:5rem}}@media only screen and (min-width: 992px){.Avatar_base__1TXzk{width:3.5rem;height:3.5rem}}

.HomeContentLeft_personal__1WOOx{display:flex;align-items:center;font-weight:700;font-size:1rem;margin:0 0 2rem 0}@media only screen and (min-width: 576px){.HomeContentLeft_personal__1WOOx{font-size:1.125rem}}@media only screen and (min-width: 992px){.HomeContentLeft_personal__1WOOx{font-size:1.25rem}}.HomeContentLeft_headline__Exow8{font-size:1.5rem;font-weight:700;min-height:32px;margin:0 0 2rem 0}@media only screen and (min-width: 992px){.HomeContentLeft_headline__Exow8{min-height:116px;font-size:2.75rem}}.HomeContentLeft_description__3Rvut{font-size:1rem;color:#898c91;font-weight:200;margin:0 0 1.5rem 0}.HomeContentLeft_description__3Rvut:last-child{margin-bottom:2.5rem}.HomeContentLeft_socialMedia__9Hliy{display:flex;flex-wrap:wrap}

.SocialMediaBadge_base__1C8cv{border-radius:10px;transition:box-shadow 250ms, color 250ms, -webkit-transform 250ms;transition:transform 250ms, box-shadow 250ms, color 250ms;transition:transform 250ms, box-shadow 250ms, color 250ms, -webkit-transform 250ms;background-color:#111217;color:#fff;font-size:.625rem;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:1rem;margin:0 1rem 1rem 0;-webkit-user-select:none;-ms-user-select:none;user-select:none}@media only screen and (max-width: 575px){.SocialMediaBadge_base__1C8cv{width:calc(50vw - 7rem / 2);margin:0 0 1rem 0}.SocialMediaBadge_base__1C8cv:nth-child(1),.SocialMediaBadge_base__1C8cv:nth-child(3n){margin:0 1rem 1rem 0}}@media only screen and (min-width: 576px){.SocialMediaBadge_base__1C8cv{min-width:5.5rem}}@media only screen and (min-width: 992px){.SocialMediaBadge_base__1C8cv{margin:0 1rem 1rem 0}}.SocialMediaBadge_base__1C8cv span,.SocialMediaBadge_base__1C8cv span:hover{color:#fff}.SocialMediaBadge_base__1C8cv:hover{-webkit-transform:translateY(-0.5rem);transform:translateY(-0.5rem)}.SocialMediaBadge_base__1C8cv:hover:nth-child(even){color:#7747b6;box-shadow:0px 0.5rem 0px 0px #7747b6}.SocialMediaBadge_base__1C8cv:hover:nth-child(odd){color:#8e7cc3;box-shadow:0px 0.5rem 0px 0px #8e7cc3}.SocialMediaBadge_base__1C8cv:last-child{margin-right:0}.SocialMediaBadge_icon__nrOLp{height:2rem;width:2rem;margin-bottom:0.5rem}

.Card_base__3g7A5{display:flex;flex-direction:column;padding:1rem;margin:0 0 1rem;border-radius:10px;background-color:#15171d;transition:color 250ms, box-shadow 250ms, -webkit-transform 250ms;transition:transform 250ms, color 250ms, box-shadow 250ms;transition:transform 250ms, color 250ms, box-shadow 250ms, -webkit-transform 250ms;position:relative;color:#898c91}@media only screen and (min-width: 992px){.Card_base__3g7A5{padding:1.5rem;margin:0 0 2rem}}.Card_base__3g7A5:hover{-webkit-transform:translateX(0.5rem);transform:translateX(0.5rem)}.Card_base__3g7A5:hover:nth-child(even){box-shadow:-0.5rem 0px 0px 0px #acd8da;color:#acd8da}.Card_base__3g7A5:hover:nth-child(odd){box-shadow:-0.5rem 0px 0px 0px #184773;color:#184773}

.CardFooter_base__2LEp8{display:flex;flex-direction:row;justify-content:space-between;font-size:.75rem;margin-top:1rem;-webkit-user-select:none;-ms-user-select:none;user-select:none}.CardFooter_stats__39H7Q{display:flex;align-items:center}.CardFooter_stat__1Ijjt{align-items:center;display:flex;margin-right:0.75rem}.CardFooter_icon__2AkKU{height:1.125rem;width:1.125rem;margin-right:0.25rem}.CardFooter_statLabel__3lrF3{color:#898c91}.CardFooter_externalLink__3AlPl{height:1.125rem;width:1.125rem;color:#898c91}

.ContactCard_subheadline__31uVU{font-size:.875rem;font-weight:300;color:#1a68af;margin-bottom:0.25rem}.ContactCard_headline__3J2ut{font-size:1.125rem;font-weight:700;color:#fff}.ContactCard_card__3c-K7:hover{-webkit-transform:translateX(0.5rem);transform:translateX(0.5rem)}.ContactCard_card__3c-K7:hover:first-child{box-shadow:-10px 0px 0px 0px #1a68af;color:#1a68af}

.Footer_base__2-TB4{font-size:.75rem;text-align:center;color:#898c91}@media only screen and (max-width: 575px){.Footer_base__2-TB4{margin-bottom:2rem}}.Footer_links__3Cx6x{display:flex;justify-content:center;margin-top:0.75rem}.Footer_link__9_ojg{margin:0 0.25rem;transition:color 250ms}.Footer_link__9_ojg:hover{color:#8e7cc3}

.CardHeader_base__2U_3u{display:flex;align-items:center;margin-bottom:1rem;color:#fff;-webkit-user-select:none;-ms-user-select:none;user-select:none}.CardHeader_icon__1RGs8{height:1.25rem;width:1.25rem;margin-right:0.5rem}

.CardContent_base__1gqYi{display:flex;flex-direction:column}.CardContent_title__1l0kg{font-weight:500;font-size:1rem;margin-bottom:0.5rem;color:#fff}.CardContent_description__1Y6Pi{color:#898c91;font-weight:300;font-size:.875rem}

.HomeContentRight_cards__1GuTQ{margin-bottom:1.5rem;display:flex;flex-direction:column}

.Layout_base__-PpTJ{display:flex;flex-direction:column;max-height:100vh;height:100vh}@media only screen and (min-width: 992px){.Layout_base__-PpTJ{flex-direction:row}}.Layout_contentLeft__1Pr6v,.Layout_contentRight__13Lum{flex:1 1;padding:2rem 1rem}@media only screen and (min-width: 576px){.Layout_contentLeft__1Pr6v,.Layout_contentRight__13Lum{padding:2rem}}@media only screen and (min-width: 768px){.Layout_contentLeft__1Pr6v,.Layout_contentRight__13Lum{padding:4rem}}@media only screen and (min-width: 992px){.Layout_contentLeft__1Pr6v,.Layout_contentRight__13Lum{padding:5rem;overflow-y:scroll;max-height:100vh;width:50%}}@media only screen and (min-width: 1200px){.Layout_contentLeft__1Pr6v,.Layout_contentRight__13Lum{padding:6rem}}.Layout_contentLeft__1Pr6v{background-color:#15171d}

.Home_base__2HO0s{margin:0}

.Imprint_base__yvW2b{margin:0}

.ImprintContentLeft_base__2IEeP{display:flex;flex-direction:column;padding-top:4rem}@media only screen and (min-width: 992px){.ImprintContentLeft_base__2IEeP{padding-top:0}}.ImprintContentLeft_base__2IEeP h5{margin-top:1rem;font-weight:500;font-size:1.125rem;margin-bottom:1rem}.ImprintContentLeft_base__2IEeP h5:first-child{margin-top:0}.ImprintContentLeft_base__2IEeP p{font-size:.875rem;color:#898c91;font-weight:300;margin-top:0}.ImprintContentLeft_base__2IEeP p strong{color:#fff}.ImprintContentLeft_headline__31Eyk{font-size:1.5rem;font-weight:700;min-height:32px;margin:0 0 2rem 0}@media only screen and (min-width: 992px){.ImprintContentLeft_headline__31Eyk{min-height:116px;font-size:2.75rem}}

.ImprintContentRight_base__2SKHS{display:flex;flex-direction:column}.ImprintContentRight_base__2SKHS h5{margin-top:1rem;font-weight:500;font-size:1.125rem;margin-bottom:1rem}.ImprintContentRight_base__2SKHS h5:first-child{margin-top:0}.ImprintContentRight_base__2SKHS p{font-size:.875rem;color:#898c91;font-weight:300;margin-top:0}.ImprintContentRight_content__3g9Vn{margin-bottom:3.5rem}

.Backwards_base__11NOc{position:absolute;left:1rem;top:1rem;width:3rem;height:3rem;border-radius:50%;background-color:#111217;display:flex;justify-content:center;align-items:center;transition:box-shadow 250ms, -webkit-transform 250ms;transition:transform 250ms, box-shadow 250ms;transition:transform 250ms, box-shadow 250ms, -webkit-transform 250ms}@media only screen and (min-width: 992px){.Backwards_base__11NOc{left:2rem;width:4rem;height:4rem}}.Backwards_base__11NOc:hover{-webkit-transform:translate(0.5rem, -0.5rem);transform:translate(0.5rem, -0.5rem);box-shadow:-0.5rem 0.5rem 0px 0px #7747b6}.Backwards_icon__t7B1Q{height:1.25rem;width:1.25rem}@media only screen and (min-width: 992px){.Backwards_icon__t7B1Q{height:1.5rem;width:1.5rem}}

body{background-color:#111217;height:100vh;width:100vw;font-size:1rem;color:#fff;margin:0;font-family:"Roboto Mono", monospace}body ::-webkit-scrollbar,html ::-webkit-scrollbar{width:0}body ::selection,html ::selection{color:#fff;background-color:#8e7cc3}a{text-decoration:none;color:#fff}.switch-wrapper{position:relative}.switch-wrapper>div{position:absolute}

