@import "../../styles/index";

.base {
  border-radius: 10px;
  transition: transform 250ms, box-shadow 250ms, color 250ms;
  background-color: $color-chinese-black;
  color: $color-white;
  font-size: $font-size-10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0 1rem 1rem 0;
  user-select: none;

  @include media-to(xs) {
    width: calc(50vw - 7rem / 2);

    margin: 0 0 1rem 0;

    &:nth-child(1),
    &:nth-child(3n) {
      margin: 0 1rem 1rem 0;
    }
  }

  @include media-from(xs) {
    min-width: 5.5rem;
  }

  @include media-from(md) {
    margin: 0 1rem 1rem 0;
  }

  span,
  span:hover {
    color: $color-white;
  }

  &:hover {
    transform: translateY(-0.5rem);

    &:nth-child(even) {
      color: $color-royal-pruple;
      box-shadow: 0px 0.5rem 0px 0px $color-royal-pruple;
    }

    &:nth-child(odd) {
      color: $color-ube;
      box-shadow: 0px 0.5rem 0px 0px $color-ube;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.icon {
  height: 2rem;
  width: 2rem;
  margin-bottom: 0.5rem;
}
