@import "../../styles/index";

.base {
  border-radius: 50%;
  background-color: $color-chinese-black;
  margin: 0 1rem 0 0;
  width: 3rem;
  height: 3rem;

  user-select: none;

  @include media-from(xs) {
    margin: 0 2rem 0 0;
    width: 5rem;
    height: 5rem;
  }

  @include media-from(md) {
    width: 3.5rem;
    height: 3.5rem;
  }
}
