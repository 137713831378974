$color-chinese-black: #111217;
$color-eerie-black: #15171d;

$color-philippine-gray: #898c91;

$color-white: #ffffff;

$color-royal-pruple: #7747b6;
$color-ube: #8e7cc3;
$color-green-blue: #1a68af;
$color-crystal: #acd8da;
$color-dark-cerulean: #184773;
